import Swal from "sweetalert2";

function clean_ci(ci) {
    return ci.replace(/\D/g, '');
}
function validation_digit(ci) {
    var a = 0;
    var i = 0;
    if (ci.length <= 6) {
        for (i = ci.length; i < 7; i++) {
            ci = '0' + ci;
        }
    }
    for (i = 0; i < 7; i++) {
        a += (parseInt("2987634"[i]) * parseInt(ci[i])) % 10;
    }
    if (a % 10 === 0) {
        return 0;
    } else {
        return 10 - a % 10;
    }
}

function validateEmail(email) {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
}

export function formatMoney(lp) {
    const hashMoneys = { UYU: '$', USD: 'U$S', BRL: 'R$' }
    return hashMoneys[lp];
}

export function adjust10th(setting, number) {
    number = parseFloat(number);
    let ajusteTotalConfig = 0;
    let ajuste = Math.abs(number) % 10;
    if (setting === "5d") {
        if (ajuste > 5 && ajuste <= 7) {
            ajusteTotalConfig = 5 - ajuste;
        } else if (ajuste > 8) {
            ajusteTotalConfig = 10 - ajuste;
        } else if (ajuste >= 1 && ajuste <= 2) {
            ajusteTotalConfig = -ajuste;
        } else if (ajuste >= 3 && ajuste <= 4) {
            ajusteTotalConfig = 5 - ajuste;
        }
    }
    if (setting === "10d") {
        if (ajuste >= 5) {
            ajusteTotalConfig = 10 - ajuste;
        } else if (ajuste < 5 && ajuste != 0) {
            ajusteTotalConfig = -ajuste;
        }
    }
    return ajusteTotalConfig;
}

export function calcSalePrice(price, discount, decimal) {
    price = parseFloat(price);
    discount = parseFloat(discount);
    return parseFloat(price - (discount / 100 * price)).toFixed(decimal);
}

export function formatPrice(price, decimal) {
    price = parseFloat(price);
    const formatter = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: decimal,
    });

    return formatter.format(price);
}

export function formatPricePayexpress(price, decimal) {
    return (price / 100).toFixed(decimal);
}

export function calcSubTotal(price, count) {
    price = parseFloat(price);
    count = parseFloat(count);
    return parseFloat(price * count);
}

export function validarCedula(ci) {
    if(!ci){
        return false;
    }
    if(ci.trim() == ""){
        return false;
    }
    if (!/^\d+$/.test(ci)) {
        return false;
    }

    ci = clean_ci(ci);
    var dig = ci[ci.length - 1];
    ci = ci.replace(/[0-9]$/, '');
    return (dig == validation_digit(ci));
}

export function validarRuc(rut) {
    var digito;
    clean_ci(rut);
    rut.replace(/[0-9]$/, '');
    var digitoVerificador = 0;
    var col = [4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];

    if (rut.length == 12) {
        digito = parseInt(rut.substring(rut.length - 1));

        for (var i = 0; i < col.length; i++) {
            digitoVerificador += col[i] * parseInt(rut.charAt(i));
        }

        digitoVerificador = 11 - (digitoVerificador % 11);

        if (digitoVerificador == 11) {
            digitoVerificador = 0;
        } else if (digitoVerificador == 10) {
            digitoVerificador = 1;
        }

        if (digitoVerificador == digito) {
            return true;
        } else {
            return false;
        }
    }

    return false;
}

export function validateSendMails(emails) {
    var mailvalido = true;
    var mailarrays = [];
    if (emails.includes(',')) {
        mailarrays = emails.split(',');
    } else if (emails.includes(';')) {
        mailarrays = emails.split(';');
    }

    if (mailarrays.length) {
        mailarrays.forEach(function (str) {
            if (!validateEmail(str)) {
                mailvalido = false;
                emails = emails.replace(str, '');
                if (emails.charAt(0) === ',' || emails.charAt(0) === ';') {
                    emails = emails.substr(1);
                } else if (emails.includes(',,')) {
                    emails = emails.replace(',,', ',');
                } else if (emails.includes(';;')) {
                    emails = emails.replace(';;', ';');
                } else if (emails.charAt(emails.length - 1) === ',' || emails.charAt(emails.length - 1) === ';') {
                    emails = emails.slice(0, -1);
                }
            }
        });
    } else {
        if (!validateEmail(emails)) {
            mailvalido = false;
        }
    }
    var returnMails = { valid: true, emails: '' };
    if (mailvalido) {
        returnMails.valid = true;
        returnMails.emails = emails;
    } else {
        returnMails.valid = false;
        returnMails.emails = emails;
    }
    return returnMails;
}
export function tipoDocumentos(documento) {
    const hashDocumentos = {
        "2": "RUT",
        "3": "Cédula",
        "4": "Sin especificar",
    }
    return hashDocumentos[documento];
}

export function tipoComprobantes(comprobante) {
    const hashComprobantes = {
        "101": "e-Ticket",
        "102": "Nota de Crédito de e-Ticket",
        "103": "Nota de Débito de e-Ticket",
        "111": "e-Factura",
        "112": "Nota de Crédito de e-Factura",
        "113": "Nota de Débito de e-Factura",
        "121": "e-Factura de Exportación",
        "122": "Nota de crédito de e-Factura de Exportación",
        "123": "Nota de débito de e-Factura de Exportación",
        "124": "e-Remito de Exportación",
        "131": "e-Ticket Cta Ajena",
        "132": "NC e-Ticket Cta Ajena",
        "133": "ND e-Ticket Cta Ajena",
        "141": "e-Factura Cta Ajena",
        "142": "NC e-Factura Cta Ajena",
        "143": "ND e-Factura Cta Ajena",
        "151": "e-Boleta",
        "152": "Nota Credito e-Boleta",
        "153": "Nota Debito  e-Boleta",
        "181": "e-Remito",
        "182": "e-Resguardo",
        "201": "e-Ticket Contingencia",
        "202": "Nota de Crédito de e-Ticket Contingencia",
        "203": "Nota de Débito de e-Ticket Contingencia",
        "211": "e-Factura Contingencia",
        "212": "Nota de Crédito de e-Factura Contingencia",
        "213": "Nota de Débito de e-Factura Contingencia",
        "221": "e-Factura de Exportación Contingencia",
        "222": "Nota de crédito de e-Factura de Exportación Contingencia",
        "223": "Nota de débito de e-Factura de Exportación Contingencia",
        "224": "e-Remito de Exportación Contingencia",
        "231": "e-Factura Cta Aj Cont",
        "241": "e-Factura Cta Aj Cont",
        "251": "e-Boleta Contingencia",
        "252": "e-Boleta NC Contingencia",
        "253": "e-Boleta ND Contingencia",
        "281": "e-Remito Contingencia",
        "282": "e-Resguardo Contingencia",
        "901": "Recibo",
    }
    return hashComprobantes[comprobante];
}

export function borrarCarrito() {
    localStorage.removeItem('product');
}

export function getStrategyImage(state) {
    const strategyImage = {
        '1': 'cover',
        '2': 'scale-down',
        '3': 'fill'
    }
    return strategyImage[state];
}

export function getResponsiveCard(state) {
    const strategyImage = {
        '1': 'col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxl-2 px-1',
        '2': 'col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-xxl-3 px-1',
        '3': 'col-6 col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxl-2 px-0 px-sm-1',
        '4': 'col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-xxl-3 px-0 px-sm-1'
    }
    return strategyImage[state];
}


export function goTo(id, adjust = 0) {
    // console.log("id: " + id)
    // console.log("adjust: " + adjust)
    if (id) {
        const scrollDiv = document.getElementById(id).offsetTop;
        window.scrollTo({ top: scrollDiv - adjust, behavior: 'smooth' });
    }

}


export function validarInputNombreCheckout(valorInput) {
    return /^[a-zA-ZáéíóúüñÁÉÍÓÚÜÑ\s]*$/.test(valorInput);
}
export function validarInputsCheckout(valorInput) {
    return /^[a-zA-ZáéíóúüñÁÉÍÓÚÜÑ0-9\s]*$/.test(valorInput);
}
export function validarTelefonoCheckout(valorInput) {
    return /^[0-9+]*$/.test(valorInput);
}

export function randomTransactionId() {
    let randomNumber = Math.floor(Math.random() * 999999); // genera un número aleatorio entre 0 y 999999
    let randomString = randomNumber.toString(); // convierte el número a una cadena
    if (randomString.length > 6) {
        randomString = randomString.substr(0, 6); // trunca la cadena a 6 dígitos si es necesario
    }
    return parseInt(randomString);
}

export function extraerCodigoScript(cadena) {
    const regex = /<script>([\s\S]*)<\/script>/i; // Expresión regular para buscar el código entre las etiquetas <script> y </script>
    const matches = cadena.match(regex); // Busca coincidencias en la cadena
    if (matches && matches.length > 1) {
        return matches[1].trim(); // Devuelve el código entre las etiquetas (segundo elemento de las coincidencias) sin espacios en blanco iniciales y finales
    } else {
        return null; // No se encontró código entre las etiquetas
    }
}
export function extraerValorPropiedad(metaetiqueta, propiedad) {
    const regex = new RegExp(`${propiedad}='([^']*)'`);
    const match = regex.exec(metaetiqueta);
    if (match && match.length > 1) {
        return match[1];
    } else {
        return null;
    }
}

export function obtenerAnchoElemento(miElemento) {


    // Obtener el ancho del elemento
    const anchoElemento = miElemento.offsetWidth;

    /* // Obtener el padding del elemento
    const paddingElemento = parseInt(getComputedStyle(miElemento).getPropertyValue('padding-left')) 
      + parseInt(getComputedStyle(miElemento).getPropertyValue('padding-right')); */

    // Obtener el margin del elemento
    const marginElemento = parseInt(getComputedStyle(miElemento).getPropertyValue('margin-left'))
        + parseInt(getComputedStyle(miElemento).getPropertyValue('margin-right'));

    // Obtener el ancho total del elemento
    return anchoElemento + marginElemento;
}

export function getDarkerColor(color, amount){
    const r = parseInt(color.substr(1, 2), 16);
    const g = parseInt(color.substr(3, 2), 16);
    const b = parseInt(color.substr(5, 2), 16);

    const darkerR = Math.max(r - amount, 0);
    const darkerG = Math.max(g - amount, 0);
    const darkerB = Math.max(b - amount, 0);

    const darkerHex = `#${darkerR.toString(16).padStart(2, '0')}${darkerG.toString(16).padStart(2, '0')}${darkerB.toString(16).padStart(2, '0')}`;

    return darkerHex;
}
  
export function colocarMetaEtiqueta(property, content){
    const metaEtiqueta = document.createElement("meta");
    metaEtiqueta.setAttribute('property',property);
    metaEtiqueta.setAttribute('content', content);
    document.head.appendChild(metaEtiqueta);
}

export function capitalizarPalabras(string) {
    // Separa el string en un arreglo de palabras
    var palabras = string.split(' ');
  
    // Itera sobre cada palabra en el arreglo
    for (var i = 0; i < palabras.length; i++) {
      var palabra = palabras[i];
  
      // Capitaliza el primer carácter de la palabra y lo combina con el resto de la palabra en minúsculas
      palabras[i] = palabra.charAt(0).toUpperCase() + palabra.slice(1).toLowerCase();
    }
  
    // Une las palabras capitalizadas en un nuevo string
    var resultado = palabras.join(' ');
  
    return resultado;
}

export function validarProductoPesable(producto) {
    return  producto.pesable && producto.pesable != "S" ? Number.isSafeInteger(producto.cantidad) && !Number.isNaN(producto.cantidad) : !Number.isNaN(producto.cantidad);
}

export function desplegarSwalProductoPesable(titulo, texto) {
    Swal.fire({
      icon: "warning",
      title: titulo,
      html: texto,
      confirmButtonText: "Ok",
    }).then(() => {
      return false;
    });
  }

  export function ajustarTamañoFuente(contenedor, hijo) {
    // Obtener el tamaño actual del contenedor y del texto
    const contenedorWidth = contenedor.offsetWidth;
    const textoWidth = hijo.offsetWidth;
  
    // Calcular la escala de la fuente en función del desbordamiento
    const escala = contenedorWidth / textoWidth;
  
    // Aplicar la escala a la fuente
    hijo.style.fontSize = `${16 * escala}px`; // Tamaño de fuente inicial multiplicado por la escala
  }
  
export function getFormattedDate() {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
  
    return `${year}${month}${day}`;
}

export function getFont(font){
    const fonts = {
        "-1" : 'system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
        "1" : "'Raleway', sans-serif",
        "2" : "'Poppins', sans-serif",
        "3" : "'Montserrat', sans-serif"
    }
    return fonts[font];
}

export function capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

export function obtenerDominio(url) {
    // Elimina "http://" o "https://" al principio de la URL usando una expresión regular
    const dominio = url.replace(/^(https?:\/\/)?/, '');
    
    return dominio;
}

export function escalarImagen(e, scale, container){
         const bounds = container.getBoundingClientRect();
          const x = e.pageX - window.scrollX - bounds.left;
          const y = e.pageY - window.scrollY - bounds.top;
          const img = container.querySelector('img');
          img.style.transformOrigin = `${x}px ${y}px`;
          img.style.transform = "scale("+scale+")";
}

export function normalizarImagen(container){
    const img = container.querySelector('img');
    img.style.transformOrigin = 'center center';
    img.style.transform = "scale(1)";
}

export function zoomChild(arrayContainers, scale) {
    arrayContainers.forEach(container => {
        const mouseMoveHandler = (e) => escalarImagen(e, scale, container);
        const mouseLeaveHandler = () => normalizarImagen(container);

        container.addEventListener('mousemove', mouseMoveHandler);
        container.addEventListener('mouseleave', mouseLeaveHandler);

        // Guarda las referencias en el elemento para poder remover los eventos más tarde
        container._zoomMouseMoveHandler = mouseMoveHandler;
        container._zoomMouseLeaveHandler = mouseLeaveHandler;
    });
}

export function removeZoom(arrayContainers) {

    arrayContainers.forEach(container => {
        if (container._zoomMouseMoveHandler && container._zoomMouseLeaveHandler) {
            container.removeEventListener('mousemove', container._zoomMouseMoveHandler);
            container.removeEventListener('mouseleave', container._zoomMouseLeaveHandler);

            // Opcional: Limpiar las referencias
            delete container._zoomMouseMoveHandler;
            delete container._zoomMouseLeaveHandler;
        }
    });
}

export function validarCelular(cel) {

    if(!cel){
        return false;
    }
    if(cel.trim() == ""){
        return false;
    }

    let prefijo = cel.substring(0,1);
    let prefijo2 = cel.substring(0,4);
    let prefijo3 = cel.substring(0,2);

    if(prefijo === '+' && cel.length !== 12){
        return false;
    } else if(prefijo === '0' && cel.length !== 9){
        return false;
    } else if((prefijo !== '+' && prefijo !== '0') || (prefijo === '+' && prefijo2 !== '+598') || (prefijo === '0' && prefijo3 !== '09')){
        return false;
    }

    return true;

}